/**
 * @desc 机动车图库
 */

import React from 'react'
import Wrapper from './components/wrapper'
import './index.less'

export default class vehicleLibraryIndex extends React.Component {
  render(){
    return <Wrapper />
  }
}
