/**
 * @desc 机动车图库
 * @modify tlzj 2019-1-22
 * @modify tlzj 2019-3-04
 */

/**
 * @desc state 参数
 * @param {Array} list 列表数据
 * @param {Boolean} loading 加载状态
 * @param {String} size 图片查看状态  default: normal normal(默认) small(小)  large(大)
 * @param {Boolean} isSearch 是否是以图搜图
 * @param {Array} checkedIds 生成轨迹选中的id
 * @param {Array} this.searchType 查询类型 0: 以图搜图 1： 上传图片进行搜图
 * @param {Boolean} this.initTime 初始进入页面时先出现loading,不出现noData组件
 * @param {Object} this.urlParams 地址栏参数
 */

/**
 * tips:
 * 1.上传图片搜图--上传图片获取url/base64 ---> 根据url/base64获取特征值 ---> 根据特征值获取列表
 * 2.关联搜图--使用id获取特征值
 */

import React from 'react';
import { message } from 'antd';
import Search from './search';
import { observer } from 'mobx-react';
import _ from 'lodash';
import { toJS } from 'mobx';
import { withRouter } from 'react-router-dom';
const Content = Loader.loadBusinessComponent('ResourceComponents', 'Content');
const Wrapper = Loader.loadBusinessComponent('BaseLibComponents', 'Wrapper');
const RightHeader = Loader.loadBusinessComponent('BaseLibComponents', 'RightHeader');
const TitleOptions = Loader.loadBusinessComponent('BaseLibComponents', 'TitleOptions');
const SearchMapModal = Loader.loadBusinessComponent('BaseLibComponents', 'SearchMapModal');

const structurError = '提取图片特征失败，请重新上传图片！';
const Loading = Loader.Loading;

@withRouter
@Decorator.withEntryLog()
@Decorator.businessProvider('vehicle', 'tab', 'user')
@Decorator.businessObserver({ vehicle: ["searchData"], user: ["userHabit"] })
class VehicleLibrary extends React.Component {
  constructor(props) {
    super(props);
    this.initTime = true;
    this.urlParams = Utils.queryFormat(props.location.search);
    this.indexDBResult = {};
    this.refContent = React.createRef()
    this.state = {
      list: [], //列表
      loading: true,
      size: props.user.userHabit.vehicleModel || 'normal',
      url: '',
      key: Math.random(),
      cKey: Math.random(),
      checkedIds: [],
      frameUrl: '', // 模态框Url
      rects: [], //结构化信息
      imgVisible: false // 框选模态框
    };
  }
  componentDidMount() {
    const options = this.urlParams,
      searchType = this.urlParams.searchType;
    this.suffix = this.props.location.pathname.split('/')[1];
    const { searchData } = this.props.vehicle;
    if (options.id) {
      LM_DB.get('parameter', options.id).then(result => {
        // 过滤掉其他类型的结构化信息
        if (Array.isArray(result.rects)) {
          result.rects = result.rects.filter(v => v.type === 'vehicle');
        }
        this.indexDBResult = result;
        if (result && options.isSearch) {
          // 如果是搜图
          this.setState({
            url: result.url,
            frameUrl: result.frameUrl,
            rects: result.rects
          });
          if (this.getPictureBySearch()) {
            this.initGetFearture(result);
          } else {
            if (searchType === '0') {
              console.log(result, 87);
              // 关联搜图 --- imgId  result.url为了兼容从详情切到列表刷新后找不到url的问题
              this.setState(
                {
                  url: result.url || result.data.vehicleUrl,
                  key: Math.random(),
                  frameUrl: result.frameUrl || result.data.sceneUrl
                },
                () => {
                  this.mergeSearchData({
                    ...Utils.getTimerArea(searchData.timerTabsActive),
                    ...result.searchData,
                    imgId: this.urlParams.id
                  });
                }
              );
            }
            if (searchType === '2') {
              // 兼容特征值没有的情况
              if (result.feature) {
                const option = Object.assign({}, searchData, { feature: result.feature, ...result.searchData, imgId: result.imgId, id: result.smId });
                this.mergeSearchData(option, true, true);
              } else {
                this.initGetFearture(result);
                const id = this.urlParams.searchType === '0' ? Utils.uuid() : this.urlParams.id;
                const urlParamsObj = Object.assign({}, this.urlParams, { id, searchType: 1 });
                this.urlParams = urlParamsObj;
                const indexDBObj = {
                  id,
                  searchData: toJS(this.props.vehicle.searchData),
                  url: result.url,
                  frameUrl: result.frameUrl
                };
                this.changeIndexDBData(indexDBObj, urlParamsObj);
              }
            }
          }
        } else {
          // 用户带缓存参数进入（非搜图），普通列表展示
          if (result) {
            this.mergeSearchData({
              ...Utils.getTimerArea(searchData.timerTabsActive),
              ...result.searchData,
            }).then(() => {
              this.setState({
                key: Math.random() // 搜索框更新（回显刷新后的搜索框）
              });
            });
          } else {
            // indexDB读取失败 ，查询全部
            this.mergeSearchData(Utils.getTimerArea(searchData.timerTabsActive));
          }
        }
      });
    } else {
      // 列表查询
      this.mergeSearchData(Utils.getTimerArea(searchData.timerTabsActive));
    }
  }

    static getDerivedStateFromProps(nextProps, prevState) {
    const vehicleModel = nextProps.user.userHabit.vehicleModel
    if(vehicleModel && vehicleModel !== prevState.size && nextProps.storeId === BaseStore.tab.currentId){
      return {size:nextProps.user.userHabit.vehicleModel}
    }
    return null
  }

  initGetFearture = result => {
    const { searchData } = this.props.vehicle;
    this.getFearture(result.url)
      .then(res => {
        const list = (res.data && res.data.vehicles) || [];
        let arr = [];
        if (list.length) {
          arr = list.map(v => {
            return {
              feature: v.feature,
              type: 'vehicle',
              value: `${v.rect.left},${v.rect.top},${v.rect.width},${v.rect.height}`
            };
          });
        }
        if (list.length > 1) {
          this.setState({
            imgVisible: true,
            key: Math.random()
          });
        }
        if (list.length === 1) {
          this.setState({
            imgVisible: false,
            key: Math.random()
          });
          this.mergeSearchData({
            ...Utils.getTimerArea(searchData.timerTabsActive),
            ...result.searchData,
            feature: list.length ? list[0].feature : '',
            imgId: result.imgId,
            id: result.smId
          });
        }
        if (!list.length) {
          this.mergeSearchData({
            ...Utils.getTimerArea(searchData.timerTabsActive),
            ...result.searchData,
            feature: '',
            imgId: result.imgId,
            id: result.smId
          });
        }
      })
      .catch(err => {
        this.initTime = false;
        message.warning(err.data.message || structurError);
        // this.mergeSearchData({
        //   ...Utils.getTimerArea(searchData.timerTabsActive),
        //   ...result.searchData,
        //   feature: ''
        // });
        this.setState({
          loading: false,
          imgVisible: false,
          key: Math.random()
        });
      });
  };
  /**
   * @desc 进行图片结构化
   */
  getFearture = url => {
    let isBase64 = false;
    if (url && url.indexOf('data:image/png;base64') >= 0) {
      url = url.split(',')[1];
      isBase64 = true;
    }
    return Service.vehicle.getFeature({
      [isBase64 ? 'base64' : 'url']: url
    });
  };
  /**
   * @desc 根据url获取查询条件
   */
  initUrlOptions = params => {
    const { score } = this.props.vehicle.searchData;
    const { url } = this.state;
    const options = this.urlParams;
    //根据id查询
    // if (options.isSearch && !this.getPictureBySearch()) {
    //   params.imgId = this.indexDBResult.imgId;
    // }
    if (options.isSearch && this.urlParams.searchType === 0) {
      params.imgId = this.urlParams.id;
    }
 
    //以图搜图
    // if (options.isSearch && this.getPictureBySearch()) {
    //   if (url && url.indexOf('data:image/png;base64') >= 0) {
    //     params.base64 = url.split(',')[1];
    //   } else {
    //     params.url = url;
    //   }
    // }
    params.score = score;
    return params;
  };

  /**
   * @desc 判断是否是上传图片搜图
   */
  getPictureBySearch = () => {
    return parseInt(this.urlParams.searchType, 10) === 1;
  };

  /**
   * @desc 查询
   */
  search = (isLoadMore = false) => {
    // 对机动车数据进行处理
    const { searchData } = this.props.vehicle;
    let options = Utils.vehicleOptions(searchData);
    let serviceName = 'queryPassRecords';
    /**列表 总资源 */
    if (this.urlParams.isSearch) {
      options = this.initUrlOptions(options);
      serviceName = 'queryVehiclePictures';
    }
    !isLoadMore &&
      this.setState({
        loading: true
      });
    // 日志记录
    const logData = {
      searchType: this.getPictureBySearch() ? '上传' : '图库',
      type: 'vehicle',
      url: this.state.url
    };
    return Service.vehicle[serviceName](options, logData)
      .then(results => {
        let dataList = results.data.list || [];
        this.initTime = false;
        let list = [];
        if (isLoadMore) {
          list = this.state.list;
        }
        list = list.concat(dataList);
        this.setState({
          list,
          loading: false,
          cKey: !isLoadMore ? Math.random() : this.state.cKey,
          checkedIds: !isLoadMore ? [] : this.state.checkedIds
        });
        return dataList.length;
      })
      .catch(err => {
        this.initTime = false;
        if (serviceName === 'queryVehiclePictures') {
          message.warning(err.data.message || structurError);
          this.setState({
            loading: false,
            list: []
          });
          return Promise.reject(err);
        }
        this.setState({ loading: false });
        return Promise.reject(err);
      });
  };

  /**
   * @desc 修改查询条件
   * @param {Object} options 查询条件
   * @param {Boolean} needSearch 是否进行查询, 默认查询
   * @param {Boolean} needReplace 是否存储indexDB
   */
  mergeSearchData = (options, needSearch = true, needReplace = false) => {
    if (!options.minId) {
      options.minId = undefined;
    }
    return this.props.vehicle.mergeSearchData(options).then(() => {
      if (needSearch) {
        this.setState({ loading: true });
        this.search();
        if (needReplace) {
          // 对应三种情况--1.普通列表 2.以图搜图 3.上传图片
          let id = this.urlParams.id;
          if (!this.urlParams.isSearch) {
            id = Utils.uuid();
          }
          let pageData = Object.assign({}, this.urlParams, { id }); // 跳转url参数
          let indexDBObj = null; // indexDB存储数据
          delete this.indexDBResult.expireTime;
          delete this.indexDBResult.userId;
          delete this.indexDBResult.time;
          indexDBObj = Object.assign({}, this.indexDBResult, { searchData: toJS(this.props.vehicle.searchData), id });
          this.changeIndexDBData(indexDBObj, pageData);
        }
      }
    });
  };

  /**
   * 缓存数据，更新url
   * @parma {object} indexDBObj 存入indexDB的数据
   * @parma {object} urlParams url参数修改
   */
  changeIndexDBData = (indexDBObj, urlParamsObj) => {
    this.urlParams = urlParamsObj;
    this.indexDBResult = indexDBObj;
    LM_DB.add('parameter', indexDBObj).then(() => {
      this.props.tab.goPage({
        moduleName: 'vehicleLibrary',
        location: this.props.location,
        isUpdate: true,
        data: urlParamsObj,
        action: 'replace'
      });
    });
  };

  /**
   * @desc 判断是否是上传图片搜图
   */
  getPictureBySearch = () => {
    return parseInt(this.urlParams.searchType, 10) === 1;
  };

  /**
   * @desc 刷新
   */
  Refresh = () => {
    // 自定义时间时，时间不变
    let timerTabsActive = this.props.vehicle.searchData.timerTabsActive;
    timerTabsActive === 2 ? this.mergeSearchData({}) : this.mergeSearchData(Utils.getTimerArea(timerTabsActive));
  };

  /**
   * @desc 重置
   */
  reset = () => {
    // this.state.url && this.setState({
    //   url: ''
    //  });
    this.initPageData('');
  };

  /**
   * @desc 切换图片显示大小
   */
  changesize = size => {
    const { user } = this.props;
    this.setState({ size, cKey: Math.random() });
    user.setUserHabit({vehicleModel: size});
  };

  /**
   * @desc 滚动加载
   */
  loadMore = () => {
    const { list } = this.state;
    return this.mergeSearchData(
      {
        minId: list[list.length - 1].id
      },
      false
    ).then(() => this.search(true));
  };

  /**
   * @desc 以图搜图切换图片(包含截图搜索)
   * @param {String} url base64
   */
  changeUrl = url => {
    // url改变后切换为上传图片搜图类型
    // this.setState({ url })
    if (!url) {
      // 删除图片操作 - 重置所有查询条件 - 重置url
      this.initPageData(url);
    } else {
      // 截图查询  1.以图搜图截图-----更新id   2.上传图片的情况下截图-----更新url
      this.setState({ url });
      this.props.vehicle
        .mergeSearchData({
          url,
          imgId: undefined,
          id: undefined
        })
        .then(() => {
          const id = this.urlParams.searchType === '0' ? Utils.uuid() : this.urlParams.id;
          const urlParamsObj = Object.assign({}, this.urlParams, { id, searchType: 1 });
          this.urlParams = urlParamsObj;
          this.search();
          const indexDBObj = {
            id,
            searchData: toJS(this.props.vehicle.searchData),
            url,
            frameUrl: url
          };
          this.changeIndexDBData(indexDBObj, urlParamsObj);
        });
    }
  };

  /**
   * @desc 勾选
   */
  onChecked = checkedIds => {
    const infiniteRefCurrent = this.refContent.current.infiniteRef.current;
    this.setState({ checkedIds }, () => infiniteRefCurrent && infiniteRefCurrent.forceUpdateGrid());
  };

  /**
   * @desc
   */
  initPageData = url => {
    const { vehicle } = this.props;
    this.setState({
      url,
      key: Math.random()
    });
    this.urlParams = {};
    vehicle.initData();
    vehicle.mergeSearchData(Utils.getTimerArea(vehicle.searchData.timerTabsActive)).then(() => {
      this.search();
      this.props.tab.goPage({
        moduleName: 'vehicleLibrary',
        location: this.props.location,
        isUpdate: true,
        data: {},
        action: 'replace'
      });
    });
  };

  /**
   * 车辆以图搜图详情
   */
  goPage = id => {
    const { tab, location, vehicle } = this.props;
    LM_DB.add('parameter', {
      list: this.state.list,
      searchData: {
        ...Utils.faceOptions(vehicle.searchData),
        timerTabsActive: vehicle.searchData.timerTabsActive
      },
      url: this.state.url,
      id,
      type: 'vehicle'
    }).then(() => {
      tab.goPage({
        moduleName: 'resourceSearchDetail',
        location,
        data: { id }
      });
    });
  };

  closeModal = () => {
    this.setState({
      imgVisible: false
    });
  };

  /**
   * @desc 以图搜图多个特征值点击框选搜图
   */
  handFrame = (url, rects, type, option, imageUrl) => {
    const id = Utils.uuid();
    let indexDBObj = null; // indexDB存储数据
    delete this.indexDBResult.expireTime;
    delete this.indexDBResult.userId;
    delete this.indexDBResult.time;
    indexDBObj = Object.assign({}, this.indexDBResult, { searchData: toJS(this.props.vehicle.searchData), url, id });
    let pageData = Object.assign({}, this.urlParams, { id, isSearch: true, searchType: 1 }); // 跳转url参数
    this.changeIndexDBData(indexDBObj, pageData);
    if (option) {
      this.setState({
        frameUrl: imageUrl,
        rects: rects
      });
      this.onClickDefaultRect({ ...option, imgId: undefined }, url);
    } else {
      this.setState({
        url: url,
        frameUrl: imageUrl,
        rects: rects,
        imgVisible: type
      });
    }
  };

  /**
   * @desc 以图搜图多个特征值点击默认框搜图
   */
  onClickDefaultRect = (parms = {}, url) => {
    const { searchData } = this.props.vehicle;
    const { frameUrl } = this.state;
    const option = Object.assign({}, searchData, { feature: parms.feature, id: parms.smId });
    this.mergeSearchData(option, true, true);
    this.setState({
      url,
      imgVisible: false,
      frameUrl
    });
  };

  render() {
    const { list, loading, size, cKey, url, key, checkedIds, imgVisible, rects = [], frameUrl } = this.state;
    const { searchData } = this.props.vehicle;
    const isSearch = this.urlParams.isSearch;
    let listDataIds = [];
    list.map(v => {
      if (v.latitude && v.longitude) {
        listDataIds.push(v.id);
      }
      return v;
    });
    return (
      <Loading loading={loading} wrapperClassName="baselib-spining">
        <Wrapper className="vehicle-wrapper" title="机动车图库" reset={this.reset}>
          <Search
            mergeSearchData={(values, needSearch) => this.mergeSearchData(values, needSearch, true)}
            searchData={searchData}
            key={key}
            url={url}
            isSearch={isSearch}
            changeUrl={this.changeUrl}
            handFrame={this.handFrame}
            rects={rects}
            frameUrl={frameUrl}
          />
          <Content
            list={list}
            limit={searchData.limit}
            ref={this.refContent}
            onChecked={this.onChecked}
            loadMore={this.loadMore}
            searchData={searchData}
            size={size}
            isSearch={isSearch}
            key={cKey}
            type="vehicle"
            checkedIds={checkedIds}
            goPage={this.goPage}
            initTime={this.initTime}
          />
          <div className="header-little-pagtion">
            <TitleOptions onChange={this.changesize} value={size} Refresh={this.Refresh} />
            {isSearch && (
              <RightHeader type="vehicle" list={list} suffix={this.suffix} listDataIds={listDataIds} onChecked={this.onChecked} checkedIds={checkedIds} />
            )}
          </div>
        </Wrapper>
        <SearchMapModal
          className="img-cut-modal"
          visible={imgVisible}
          width="960px"
          title="以图搜图"
          // height="540px"
          url={frameUrl}
          rects={rects}
          type="vehicle"
          otherModalFooter={true}
          onCancel={this.closeModal}
          onClickDefaultRect={this.onClickDefaultRect}
          onClickSelectRect={this.onClickSelectRect}
        />
      </Loading>
    );
  }
}

export default VehicleLibrary;
