(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("mobx"), require("antd"), require("ReactRouterDOM"), require("mobxReact"), require("_"));
	else if(typeof define === 'function' && define.amd)
		define("vehicleLibrary", ["React", "mobx", "antd", "ReactRouterDOM", "mobxReact", "_"], factory);
	else if(typeof exports === 'object')
		exports["vehicleLibrary"] = factory(require("React"), require("mobx"), require("antd"), require("ReactRouterDOM"), require("mobxReact"), require("_"));
	else
		root["vehicleLibrary"] = factory(root["React"], root["mobx"], root["antd"], root["ReactRouterDOM"], root["mobxReact"], root["_"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE__0__, __WEBPACK_EXTERNAL_MODULE__1__, __WEBPACK_EXTERNAL_MODULE__2__, __WEBPACK_EXTERNAL_MODULE__3__, __WEBPACK_EXTERNAL_MODULE__4__, __WEBPACK_EXTERNAL_MODULE__5__) {
return 